<template>
  <v-layout style="width: 100%; height: 100%; background-color: var(--v-accent-base)">
    <UserList/>
  </v-layout>
</template>

<script>
import UserList from '@/components/home/management/user/userManagement/UserList'

export default {
    components: { UserList }
}
</script>
