<template>
  <v-dialog
      v-model="createUserDialog"
      max-width="500"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" width="250" color="secondary" style="background-color: white; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);">
        <v-icon class="mr-1">mdi-account-plus-outline</v-icon>
        <span>Create new user</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="popup-header">
        <span style="font-size: 16px">CREATE NEW USER</span>
        <v-spacer/>
        <v-btn icon small @click="createUserDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4" style="background-color: var(--v-accent-base)">
        <v-form @submit="createUser" onSubmit="return false;" v-model="valid" ref="formCreate">
          <v-text-field solo-inverted outlined dense v-model="userName" label="Name" :rules="[rules.requiredUserName]"></v-text-field>
          <v-text-field solo-inverted outlined dense v-model="email" label="Email" :rules="[rules.requiredEmail]"></v-text-field>
          <v-text-field solo-inverted outlined dense v-model="phone" label="Phone number" :rules="phoneRules"></v-text-field>
          <v-select multiple small-chips solo-inverted outlined dense v-model="role" :items="roles" label="Role" :rules="[rules.role]" item-text="name" item-value="id"></v-select>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="popup-footer" style="background-color: var(--v-accent-base)">
        <v-spacer/>
        <v-btn :disabled="loading" width="120" class="mr-2" color="button" @click="resetData">
          <v-icon class="mr-1">mdi-close</v-icon>
          Cancel
        </v-btn>
        <v-btn :loading="loading" width="120" color="secondary" @click="createUser" dark>
          <v-icon class="mr-1">mdi-check</v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createUser
} from "@/backend";
export default {
  name: "PopupCreateUser",
  data () {
    return {
      loading: false,
      createUserDialog: false,
      valid: false,
      phone: undefined,
      userName: undefined,
      email: undefined,
      role: [],
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
        role: value => !!value.length || 'Role is required'
      },
      phoneRules: [
        v => !!v || 'Phone number is required',
        v =>  /[0-9]{8,}$/.test(v) || 'Phone number must be valid'
      ]
    }
  },
  props: {
    roles: {type: Array, default: () => []},
  },
  mounted() {
  },
  watch: {
    createUserDialog (val) {
      if (val) {
        this.userName = undefined
        this.email = undefined
        this.phone = undefined
        this.role = []
        if (this.$refs.formCreate) this.$refs.formCreate.resetValidation()
      }
    }
  },
  methods: {
    resetData() {
      this.userName = undefined
      this.phone = undefined
      this.email = undefined
      this.role = []
      this.$refs.formRegister.resetValidation()
    },
    async createUser () {
      this.$refs.formCreate.validate()
      if (this.valid) {
        this.loading = true
        try {
          await createUser({
            "name": this.userName,
            "phone": this.phone,
            "email": this.email,
            "role_ids": this.role
          })
          this.$store.commit('message/SHOW_SUCCESS', 'Success')
          this.$emit('getUser')
          this.createUserDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
