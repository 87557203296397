<template>
  <div class="d-flex fill-height justify-center flex-column px-8" style="width: 100%" v-if="currentUser.is_admin">
    <div style="width: 100%; height: 120px; border-bottom: 1px solid lightgrey; flex: none">
      <v-layout align-center justify-center column class="fill-height pt-2">
        <h1 class="d-flex"><v-icon color="button" size="40" class="mr-1">mdi-account-multiple</v-icon>User management</h1>
        <v-container fluid class="flex-fill overflow-y-hidden py-0" style="height: 40px; max-width: 1550px">
          <v-layout justify-end align-center class="fill-height">
            <popup-create-user :roles="roles" @getUser="getData"/>
          </v-layout>
        </v-container>
      </v-layout>
    </div>
    <v-container fluid class="d-flex flex-column overflow-y-auto" style="height: 100%; max-width: 1550px">
      <v-layout class="fill-height" style="overflow-y: auto">
        <v-data-table
            height="100%"
            fixed-header
            style="height: 100%; width: 100%"
            :headers="userHeaders"
            :items="users"
            :search="search"
            :loading="loading"
            :items-per-page="10"
            class="row-pointer order-table d-flex flex-column"
            hide-default-footer
            :server-items-length.sync="totalItem"
            loading-text="Loading... Please wait"
            :options.sync="options"
            :page.sync="page"
        >
          <template v-slot:top>
            <v-layout class="flex-wrap py-2" style="background-color: var(--v-accent-base)">
              <v-layout class="pb-2">
                <div style="flex: 1">
                  <v-select
                      clearable
                      @change="getData"
                      outlined
                      solo-inverted
                      dense
                      hide-details
                      v-model="role"
                      :items="roles"
                      item-text="name"
                      item-value="id"
                      label="Role"
                      class="mr-4 select-box"
                  ></v-select>
                </div>
                <div style="flex: 1">
                  <v-select
                      clearable
                      @change="getData"
                      outlined
                      solo-inverted
                      dense
                      hide-details
                      v-model="status"
                      :items="listStatus"
                      item-text="name"
                      item-value="code"
                      label="Status"
                      class="mr-4 select-box"
                  ></v-select>
                </div>
                <div style="flex: 3">
                </div>
                <div style="flex: 1">
                  <v-text-field
                      class="ml-1"
                      outlined
                      solo-inverted
                      dense
                      hide-details
                      v-model="search"
                      @input="debounceInput"
                      append-icon="mdi-magnify"
                      label="Search"
                  ></v-text-field>
                </div>
              </v-layout>
            </v-layout>
          </template>
          <template v-slot:[`item.roles`]="{ item }">
            <v-chip
                class="ma-1"
                small
                color="secondary"
                style="width: 100px;"
                v-for="(role, index) in item.roles"
                :key="index">
              {{ role.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon min-height="0" min-width="0" width="30" height="30" color="secondary" @click="() => $refs.updateUserForm.openDialog(item)"><v-icon size="22">mdi-account-edit-outline</v-icon></v-btn>
            <v-btn icon min-height="0" min-width="0" width="30" height="30" color="red" class="ml-2" @click="() => $refs.deleteUserForm.openDialog(item)"><v-icon size="21">mdi-delete-outline</v-icon></v-btn>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-checkbox
                @change="updateUser(item)"
                class="mt-0"
                hide-details
                color="secondary"
                v-model="item.active"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-layout>
      <div class="text-center pa-3" style="flex: none">
        <v-pagination  :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
      </div>
    </v-container>
    <PopupUpdateUser :roles="roles" @getUser="getData" ref="updateUserForm"/>
    <PopupDeleteUser @getUser="getData" ref="deleteUserForm"/>
  </div>
</template>

<script>
import {
  getAllUser, updateUser, getRole
} from "@/backend";
import {mapState} from "@/store/ults";
import {debounce} from "lodash"
import PopupCreateUser from "./CreateUser"
import PopupUpdateUser from "./UpdateUser"
import PopupDeleteUser from "./DeleteUser"
export default {
  components: {
    PopupDeleteUser,
    PopupUpdateUser,
    PopupCreateUser
  },
  data() {
    return {
      options: {},
      status: true,
      listStatus: [{name: 'Active', code: true}, {name: 'Inactive', code: false}],
      page: 1,
      search: '',
      totalItem: 100,
      pageCount: 0,
      loading: false,
      role: 2,
      roles: [],
      userHeaders: [
        {text: "Name", align: "left", sortable: true, value: "name",},
        { text: "Email", value: "email", sortable: true, align: "start" },
        { text: "Phone number", value: "phone", sortable: true, align: "start" },
        { text: "Role", value: "roles", sortable: false, align: "start" },
        { text: "Active", value: "active", sortable: false, align: "start" },
        { text: "", value: "action", sortable: false, align: "end" }
      ],
      users: []
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  watch: {
    options: {
      handler () {
        this.getData()
      },
      deep: true
    }
  },
  created() {
    if (!this.currentUser.is_admin) this.$router.push({ path: '/app' })
    this.getRole()
  },
  methods: {
    async getRole () {
      this.loading = true
      try {
        const res = await getRole()
        this.roles = res.data.filter(role => role.id !== 1)
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    },
    getParam () {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = this.options.sortBy[0]
        else sort = '-' + this.options.sortBy[0]
      }
      return {per_page: this.options.itemsPerPage, page: this.options.page, sort: sort, active: this.status, role_id: this.role, search: this.search}
    },
    debounceInput: debounce(function (e) {
      this.getData()
    }, 500),
    async getData() {
      try {
        this.users = []
        this.loading = true

        const res = await getAllUser(this.getParam())
        this.users = res.data.data
        this.totalItem = res.data.total
        this.pageCount = res.data.last_page
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async updateUser (user) {
      this.loading = true
      try {
        await updateUser({
          id: user.id,
          data: {
            "name": user.name,
            "phone": user.phone,
            "email": user.email,
            "role_ids": user.roles.map(role => role.id),
            "active": user.active
          }
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        await this.getData()
        this.loading = false
      }
    }
  },
};
</script>
<style scoped>
/deep/
.v-chip__content {
  width: 180px !important;
}
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/
.order-table th {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
/deep/
.list-image th {
  background-color: #6686a3 !important;
}
/deep/
.v-data-table__wrapper {
  border: 1px lightgrey solid;
}
</style>
